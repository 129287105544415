import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  content: {
    position: 'relative',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 16px',
    width: '100%',
  },
  grid: {
    margin: `0 auto`,
    maxWidth: 960,
   
  },
  logo: {
    height: 40,
    resizeMode: 'contain',
  },
  sapcLogo: {
    width: 62,
    height: 62,
    resizeMode: 'contain',
  },}))
