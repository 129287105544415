import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

// import CtaCallNumbers from '../CtaCallNumbers'
import Header from '../Header'
import '../../styles/sapcStyles.css'
import '../../styles/layout.css'

import '@wordpress/block-library/build-style/style.css'

import { useStyles } from './styles'

const Layout = ({ children }) => {
  const classes = useStyles()
  const [isLoaded, setLoaded] = useState(false)
  const [isHeaderOpen, setHeaderOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(/Android|iPhone|iPad|iPod/i.test(navigator.userAgent))
    setLoaded(true)
  }, [])

  const handleHeaderState = (state) => {
    setHeaderOpen(state)
  }

  return (
    <>
      <Header handleHeader={handleHeaderState} />
      <Grid
        className={classes.grid}
        style={!isMobile && isLoaded ? { maxWidth: '500px' } : null}
      >
        {!isHeaderOpen && (
          <main
            style={!isMobile && isLoaded ? { marginTop: '0' } : null}
            className={classes.content}
          >
            {children}
          </main>
        )}
      </Grid>
    </>
  )
}

export { Layout }
