import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { Container, Row, Col } from 'react-bootstrap'
import CtaLogosGov from '../components/CtaLogosGov'
import ButtonComponent from '../components/ButtonComponent'

const NotFoundPage = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 2000)

    return () => clearTimeout(timeout)

  }, [show])

  if (!show) return null

  return 
  <>
  <Layout>
    <div className="bg-color-blue-404">
      <div className="bg-intro-404">
        <Row className="pageContentSubHeader">
          <Col className="col-12 nopadding">
            <h3 className="title-page mt-4">404</h3>
          </Col>
        </Row>
      </div>
      <Seo title="404: Not found" />
      <Container>
        <div className="pushcontent404">
          <div className="mb-4 content-p">
            Sorry, but the page you were trying to view does not exist.
          </div>
          <ButtonComponent
            url={`/`}
            text="BACK TO MAIN SCREEN"
            type="orange"
            callToAction="url"
            width="80%"
          />
        </div>
      </Container>

      <CtaLogosGov />
    </div>
  </Layout>
  </>
}

export default NotFoundPage
