import { Grid } from '@material-ui/core'
import React from 'react'

import LogoFooter from '../../images/logo-footer.png'

import { useStyles } from './styles'

const CtaLogosGov = () => {
  const classes = useStyles()

  return (
    <div className={classes.footerLogo}>
      <hr />
      <Grid className={classes.mainViewCta}>
        <img
          role="none"
          alt="County of los angeles California Public Health"
          className={classes.logo}
          src={LogoFooter}
        />
      </Grid>
    </div>
  )
}

export { CtaLogosGov }
