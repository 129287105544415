import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({ 
  footerLogo: {
    backgroundColor: '#F6F7F9',
    bottom: 0,
    position: 'inherit',
    width: '100%',
  },
  logo: {
    height: 61,
    resizeMode: 'contain',
  },
  mainViewCta: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 'calc(1.45rem - 1px)',
    padding: '0 16px',
  },
}))
